import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {equals, mergeRight, omit, memoizeWith, identity} from 'ramda';

import APIController from 'dash-renderer/lib/APIController.react';
import Loading from 'dash-renderer/lib/components/core/Loading.react';
import Reloader from 'dash-renderer/lib/components/core/Reloader.react';
import Toolbar from 'dash-renderer/lib/components/core/Toolbar.react';

import {setConfig, setHooks, setAppLifecycle} from 'dash-renderer/lib/actions';
import apiThunk from 'dash-renderer/lib/actions/api';
import {urlBase} from 'dash-renderer/lib/actions/utils';
import {STATUS} from 'dash-renderer/lib/constants/constants';

import {v1 as uuid} from 'uuid';

import './debug.css';
import {getAppState} from 'dash-renderer/lib/reducers/constants';
import ReactDOM from 'react-dom';

// x-DEC version, global loading scripts mapping
window.__dashprivate_scripts = window.__dashprivate_scripts || {};

const head = document.querySelector('head');

const defaultCreateElementOptions = {
    elementType: 'div',
    attributes: {},
    innerHtml: '',
    front: false,
    insert: true,
    onload: null,
};

export const createElement = (
    container,
    options = defaultCreateElementOptions
) => {
    const {
        elementType,
        attributes,
        innerHtml,
        onload,
        front,
        insert,
    } = mergeRight(defaultCreateElementOptions, options);
    const element = document.createElement(elementType);
    if (innerHtml) {
        element.innerHTML = innerHtml;
    }
    if (onload) {
        element.onload = onload;
    }
    if (elementType === 'script') {
        element.async = false;
    }
    Object.entries(attributes).forEach(([k, v]) => element.setAttribute(k, v));
    if (insert) {
        if (front) {
            container.insertBefore(element, container.firstChild);
        } else {
            container.appendChild(element);
        }
    }
    return element;
};

function makeUrl(config, path) {
    const base = urlBase(config);
    if (path.charAt(0) === '/' && base.charAt(base.length - 1) === '/') {
        return base + path.substr(1);
    }
    return base + path;
}

class UnconnectedAppContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            loaded: 0,
            toLoad: 0,
            requestDone: false,
            prevConfig: null,
            extraConfig: {},
            id: uuid(),
            hooks: {
                request_pre: props.config.request_pre || null,
                request_post: props.config.request_post || null,
                callback_resolved: this.callback_resolved.bind(this),
                request_refresh_jwt: props.config.request_refresh_jwt
                    ? memoizeWith(identity, props.config.request_refresh_jwt)
                    : null,
            },
            running: true,
            error: null,
        };
        this.onLoaded = this.onLoaded.bind(this);
        this.setConfig = this.setConfig.bind(this);
    }

    componentDidMount() {
        this.setConfig();
        this.setupUrlWatcher();
        const {dispatch} = this.props;

        if (!this.hasRDPModulesConflict()) {
            window.React = React;
            window.ReactDOM = ReactDOM;

            if (!window.PropTypes) {
                window.PropTypes = PropTypes;
            }
        }

        dispatch(apiThunk('_resources', 'GET', 'resourcesRequest'));
    }

    callback_resolved() {
        if (this.props.config.callback_resolved) {
            this.props.config.callback_resolved(...arguments);
        }
        this.killAppIfDestroyed();
    }

    setConfig(_extraConfig) {
        const {dispatch, config} = this.props;
        const extraConfig = _extraConfig || this.state.extraConfig;

        // if the urls aren't provided correctly in the original component,
        // we won't even be able to get this far. Don't try to merge them in
        // now (they'd need to be rebased relative to the root app anyway)
        const urls = ['url_base_pathname', 'requests_pathname_prefix'];

        const combinedConfig = extraConfig
            ? mergeRight(omit(urls, extraConfig), config)
            : config;

        if (equals(combinedConfig, this.state.prevConfig)) {
            return;
        }

        const {auth_token} = config;
        const fullConfig = mergeRight(
            {
                fetch: {
                    mode: 'cors',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        Embedded: 'true',
                        'Content-Type': 'application/json',
                    },
                },
            },
            combinedConfig
        );
        if (auth_token) {
            fullConfig.fetch.headers.Authorization = `Bearer ${auth_token}`;
        }

        this.setState({prevConfig: combinedConfig});
        dispatch(setConfig(fullConfig));
    }

    loadScript(src) {
        const newScriptEntry = {
            listeners: [this.onLoaded],
            loaded: false,
            onLoaded: () => {
                newScriptEntry.loaded = true;
                newScriptEntry.listeners.forEach(listener => listener());
                newScriptEntry.listeners = [];
            },
        };
        window.__dashprivate_scripts[src] = newScriptEntry;

        createElement(head, {
            elementType: 'script',
            onload: newScriptEntry.onLoaded,
            attributes: {
                defer: true,
                src,
            },
        });
    }

    processScript(src) {
        const scriptEntry = window.__dashprivate_scripts[src];

        if (!scriptEntry) {
            this.loadScript(src);
            return 0;
        }

        if (scriptEntry.loaded) {
            return 1;
        }

        scriptEntry.listeners.push(this.onLoaded);
        return 0;
    }

    // We use this to detect when an embedded app would potentially
    // disappear by being navigated away from. If we do disappear, then
    // cancel all pending callbacks to prevent
    // https://github.com/plotly/dash-embedded-component/issues/54
    setupUrlWatcher() {
        if (!window.__dashprivate_location_hook) {
            window.history.pushState = (f =>
                function pushState() {
                    const ret = f.apply(this, arguments);
                    window.dispatchEvent(new Event('locationchange'));
                    return ret;
                })(history.pushState);

            window.history.replaceState = (f =>
                function replaceState() {
                    const ret = f.apply(this, arguments);
                    window.dispatchEvent(new Event('locationchange'));
                    return ret;
                })(history.replaceState);

            window.window.addEventListener('popstate', () => {
                window.dispatchEvent(new Event('locationchange'));
            });
            window.__dashprivate_location_hook = true;
        }

        if (!this.onLocationChangeBinded) {
            this.onLocationChangeBinded = this.onLocationChange.bind(this);
        }

        // window.addEventListener('locationchange', this.onLocationChangeBinded);
    }

    onLocationChange() {
        setTimeout(() => {
            this.killAppIfDestroyed();
        });
    }

    killAppIfDestroyed() {
        const {running} = this.state;

        if (!running) {
            // already killed
            return;
        }

        if (!document.getElementById(this.state.id)) {
            const {dispatch} = this.props;
            dispatch(setAppLifecycle(getAppState('DESTROYED')));
            this.setState({
                running: false,
            });
            window.removeEventListener(
                'locationchange',
                this.onLocationChangeBinded
            );
        }
    }

    componentDidUpdate() {
        if (this.state.error) {
            return;
        }

        // This check is necessary in case react or react-dom was added after
        // bundle.js in the case of webpack apps.
        if (this.hasRDPModulesConflict()) {
            return;
        }

        /* eslint-disable react/no-did-update-set-state */
        /* we're using setState here intentionally to track resource loading */
        this.setConfig();

        const {resourcesRequest, config, dispatch} = this.props;
        const {requestDone, toLoad, loaded, ready, hooks} = this.state;
        if (resourcesRequest.status === STATUS.OK && !requestDone) {
            let alreadyLoaded = 0;
            const {content} = resourcesRequest;
            content.js.forEach(path => {
                const src = makeUrl(config, path);
                alreadyLoaded += this.processScript(src);
            });
            this.setState({
                toLoad: content.js.length,
                loaded: alreadyLoaded,
                requestDone: true,
            });
            content.css.forEach(path => {
                const href = makeUrl(config, path);
                if (document.querySelector(`head link[href="${href}"]`)) {
                    return;
                }
                createElement(head, {
                    elementType: 'link',
                    attributes: {
                        type: 'text/css',
                        rel: 'stylesheet',
                        href,
                    },
                });
            });

            // Some config was provided with the component, so we know
            // which back end to talk to.
            // But other parts - notably devtools - come from the app
            this.setState({extraConfig: content.config});
            this.setConfig();
        }
        if (loaded >= toLoad && !ready && requestDone) {
            // When every js file has loaded has loaded we are finally ready.
            this.setState({ready: true});
        }

        dispatch(setHooks(hooks));
    }

    onLoaded() {
        this.setState({
            loaded: this.state.loaded + 1,
        });
    }

    render() {
        const {ready, prevConfig} = this.state;
        const finished = !this.state.running;

        if (finished) {
            return <div>Finished</div>;
        }

        if (this.state.error) {
            return <div style={{color: '#cc0000'}}>{this.state.error}</div>;
        }
        if (!ready) {
            return <div className="_dash-loading">Loading...</div>;
        }
        return (
            <div className="dash-absolute-debug" id={this.state.id}>
                {prevConfig.show_undo_redo ? <Toolbar /> : null}
                <APIController />
                <Loading />
                <Reloader />
            </div>
        );
    }

    hasRDPModulesConflict() {
        if (
            (window.React && window.React !== React) ||
            (window.ReactDOM && window.ReactDOM !== ReactDOM)
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                error:
                    'Duplicate react and/or react-dom libraries detected. ' +
                    'This error could be caused by react and/or react-dom modules being loaded in with the <script/> tag in a webpack app. ' +
                    'You can potentially resolve this by making sure your project ' +
                    ' doesn\'t load external react and/or react-dom scripts.'
            });
            return true;
        }
        return false;
    }
}

UnconnectedAppContainer.propTypes = {
    dispatch: PropTypes.func,
    config: PropTypes.object,
    resourcesRequest: PropTypes.object,
    callbacks: PropTypes.object,
};

const AppContainer = connect(
    state => ({
        history: state.history,
        resourcesRequest: state.resourcesRequest,
        callbacks: state.callbacks,
    }),
    dispatch => ({dispatch})
)(UnconnectedAppContainer);

export default AppContainer;
