// polyfill CustomEvent for IE11
export default (config,value) => {
    if ( typeof window.CustomEvent === "function" ) {
        setTimeout(() => {window.dispatchEvent(new CustomEvent('__dashprivate_setStateEvent', { detail: { config, value } }));}, 1);
    } else { // IE API
        const element = document.documentElement;
        const event = document.createEvent('CustomEvent');
        event.initCustomEvent('__dashprivate_onSetStateEvent', false, false, { config, value });
        setTimeout(() => { element.dispatchEvent(event); }, 1);
    }
};