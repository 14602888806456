/* eslint-env browser */

'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import DashAppInner from './AppProvider.react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import setStateEvent from './dispatchSetStateEvent';

const forcedConfig = {
    update_title: null,
};

const ConsumerContext = React.createContext();
if (!window.__dashprivate_embedded_context) {
    window.__dashprivate_embedded_context = {context: ConsumerContext};
}

class RenderDashInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...this.props.context_value};

        window.addEventListener('__dashprivate_setStateEvent', event => {
            if (event.detail.config === this.props.config) {
                this.setState(prevState => ({
                    ...prevState,
                    ...event.detail.value,
                }));
            }
        });
    }

    render() {
        return (
            <div>
                <ConsumerContext.Provider value={this.state}>
                    <DashAppInner
                        config={{...this.props.config, ...forcedConfig}}
                    />
                </ConsumerContext.Provider>
            </div>
        );
    }
}

class DashApp extends React.Component {
    render() {
        return (
            <div>
                <ConsumerContext.Provider value={this.props.value}>
                    <DashAppInner config={this.props.config} />
                </ConsumerContext.Provider>
            </div>
        );
    }
}
const dashConfigElement = document.getElementById('_dash-config');
const defaultConfiguration =
    dashConfigElement && JSON.parse(dashConfigElement.textContent);

/**
 * Render dash for compatibility with standalone dash.
 */
const renderDash = (
    configuration,
    mountPoint = 'react-entry-point',
    context_value = {}
) => {
    const config = configuration || defaultConfiguration;

    const mountPointElement =
        typeof mountPoint === 'string'
            ? document.getElementById(mountPoint)
            : mountPoint;
    ReactDOM.render(
        <RenderDashInner context_value={context_value} config={config} />,
        mountPointElement
    );
    return value => {
        setStateEvent(config, value);
    };
};

export {DashApp, renderDash};
