import React, { memo, useRef } from 'react';
import {Provider} from 'react-redux';
import { equals } from 'ramda';

import Store from 'dash-renderer/lib/store';
import AppContainer from './AppContainer.react';
import {apiRequests} from 'dash-renderer/lib/reducers/reducer';

const resourcesRequest = 'resourcesRequest';

const AppProvider = memo(props => {
    // Add the resource request into the store
    if (apiRequests.indexOf(resourcesRequest) === -1) {
        apiRequests.push(resourcesRequest);
    }

    const prevProps = useRef(undefined);
    const store = useRef(undefined);
    if (!equals(props, prevProps.current)) {
        store.current = new Store();
    }
    prevProps.current = props;
    return (
        <Provider store={store.current.store}>
            <AppContainer {...props} />
        </Provider>
    );
}
)

export default AppProvider;
